import { Box, usePrefersReducedMotion } from "@chakra-ui/react"
import { Node } from "gl-react"
import { Surface } from "gl-react-dom"
import { sample } from 'lodash'
import React, { useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useMove } from "react-use-gesture"
import useAnimationFrame from "../../hooks/useAnimationFrame"
import { shaders } from './shaders'

const randomShader = shaders[sample(Object.keys(shaders))]

function ShadersDemo({ shader = randomShader }) {
    const prefersReducedMotion = usePrefersReducedMotion()

    const [time, setTime] = useState(prefersReducedMotion ? 1000 : 0)
    const cursorPosition = useRef([0, 0])

    const { width, height, ref } = useResizeDetector()

    const bind = useMove(({ offset: [x, y] }) => {
        cursorPosition.current[0] = x
        cursorPosition.current[1] = y
    })



    useAnimationFrame(
        ({ time: currTime }) => !prefersReducedMotion && setTime(currTime),
        []
    )

    return (
        <Box
            ref={ref}
            {...bind()}
            w="full"
            maxWidth="calc(100vw - 2rem)"
            borderRadius="md"
            height={80}
            overflow="hidden"
        >
            <Surface
                width={Number.isInteger(width) ? width : "100%"}
                height={Number.isInteger(height) ? height : 320}
            >
                <Node
                    shader={shader}
                    uniforms={{ uTime: time, uCursor: cursorPosition.current }}
                />
            </Surface>
        </Box>
    )
}

export { ShadersDemo }
