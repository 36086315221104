import { Shaders, GLSL } from "gl-react"


const shaders = Shaders.create({
    noise: {
        frag: GLSL`
            precision highp float;
            varying vec2 uv;
            uniform vec2 uCursor;
            
            uniform float uTime;

            vec2 fade(vec2 t)
            {
                return t*t*t*(t*(t*6.0-15.0)+10.0);
            }

            vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }


            float snoise(vec2 v){
                const vec4 C = vec4(0.211324865405187, 0.366025403784439,
                        -0.577350269189626, 0.024390243902439);
                vec2 i  = floor(v + dot(v, C.yy) );
                vec2 x0 = v -   i + dot(i, C.xx);
                vec2 i1;
                i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
                vec4 x12 = x0.xyxy + C.xxzz;
                x12.xy -= i1;
                i = mod(i, 289.0);
                vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
                + i.x + vec3(0.0, i1.x, 1.0 ));
                vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy),
                    dot(x12.zw,x12.zw)), 0.0);
                m = m*m ;
                m = m*m ;
                vec3 x = 2.0 * fract(p * C.www) - 1.0;
                vec3 h = abs(x) - 0.5;
                vec3 ox = floor(x + 0.5);
                vec3 a0 = x - ox;
                m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
                vec3 g;
                g.x  = a0.x  * x0.x  + h.x  * x0.y;
                g.yz = a0.yz * x12.xz + h.yz * x12.yw;
                return 130.0 * dot(m, g);
            }

            void main() {
                vec2 cursor = uCursor;
                float strength = snoise(uv * 5.0) + (uTime / 25.0);
                gl_FragColor = vec4(min(sin(strength), 0.15), sin(strength * 10.0 * (uTime / 25.0)), max(strength, 0.8), max(tan(strength), 0.8));
            }
        `,
    },
    waves: {
        frag: GLSL`
            precision highp float;
            varying vec2 uv;
            
            uniform float uTime;
            uniform vec2 uCursor;

            void main() {
                vec2 wavedUv = vec2(
                    uv.x,
                    uv.y + sin(uv.x * 30.0 * uTime) * 0.1
                );
                float cursorFactor = abs(uCursor.x * 0.5) / 100.0;
                float strength = 1.0 - step(0.01, abs(distance(wavedUv, vec2(0.5)) - min(max(0.25 * cursorFactor, 0.1), 0.4)));
                gl_FragColor = vec4(strength * uv.x, sin(strength * uv.y) + 0.2 * strength, strength, strength);
            }
        `,
    },
    wheelOfDescend: {
        frag: GLSL`
            precision highp float;
            varying vec2 uv;
            
            uniform float uTime;
            uniform vec2 uCursor;

            vec2 rotate(vec2 uv, float rotation, vec2 mid)
            {
                return vec2(
                cos(rotation) * (uv.x - mid.x) + sin(rotation) * (uv.y - mid.y) + mid.x,
                cos(rotation) * (uv.y - mid.y) - sin(rotation) * (uv.x - mid.x) + mid.y
                );
            }

            void main() {
                vec2 rotatedUV = rotate(uv * max(uCursor / 70.0, 2.0), uTime * 2.0 + (uCursor.x / 80.0), vec2(0.5, 0.5));
                float strength = rotatedUV.x;
                gl_FragColor = vec4(cos(rotatedUV.x), sin(rotatedUV.y), 1.0, 1.0);
            }
        `,
    },
    theGrid: {
        frag: GLSL`
            precision highp float;
            varying vec2 uv;

            uniform float uTime;
            uniform vec2 uCursor;

            float random(vec2 st)
            {
                return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
            }

            void main() {
                float multiplier = 75.0 + ((uCursor.x * uCursor.y) / 1000.0);
                vec2 gridUv = vec2(floor(uv.x * multiplier) / multiplier, floor(uv.y * multiplier) / multiplier);
                float strength = random(gridUv * uTime);
                gl_FragColor = vec4(max(tan(floor(uv.y * multiplier) * uTime / 50.0), 0.1), max(tan(floor(uv.x * multiplier) * uTime / 10.0), 0.2), 1.0, 1.0);
            }
        `,
    },
    toxicRain: {
        frag: GLSL`
            precision highp float;
            varying vec2 uv;

            uniform float uTime;
            uniform vec2 uCursor;

            void main() {
                vec2 cursor = uCursor;
                float d = (tan(sin(uv.y) + cos(uv.x * 5.0) * (uTime)) - 1.0) * 50.0 * uTime;
                gl_FragColor = mix(
                    vec4(uv.x, uv.y, 1.0, 1.0),
                    vec4(uv.y, sin(uv.x * uTime * 5.0), 1.0, sin(uv.y * uv.x * (uTime / 5.0))),
                    step(sin(uTime), d)
                );
            }
        `,
    },
    blueTear: {
        vert: GLSL`
            varying vec2 uv;
            attribute vec2 _p;

            uniform float uTime;

            void main() {
                gl_Position = vec4(_p, 0.0, 1.0);
                uv = _p;
            }
        `,
        frag: GLSL`
            precision highp float;
            varying vec2 uv;
            uniform float uTime;

            void main() {
                vec2 cursor = uCursor;
                vec4 pattern = vec4(uv.x, uv.y, 1.0, cos((uv.y) * (uv.x / (uTime * 15.0)) * uTime * 20.0) * 40.0);
                vec4 disturb = vec4(sin(uv.y * uv.x * uTime * 5.0), tan(uv.x * uTime * 15.0), 1.0, uv.x + 1.5);
                gl_FragColor = pattern * disturb;
            }
        `,
    },
})

export {shaders}